const stations = [
  {
    id: "5qap5aO4i9A",
    name: "lofi hip hop radio - beats to relax/study to",
  },

  {
    id: "DWcJFNfaw9c",
    name: "lofi hip hop radio - beats to sleep/chill to",
  },
  {
    id: "5yx6BWlEVcY",
    name: "Chillhop Radio - jazzy & lofi hip hop beats",
  },
  {
    id: "WBfbkPTqUtU",
    name: "Tokyo LosT Tracks -サクラチル- ,chill,relax,study to,radio,",
  },
  // {
  //   id: "IiMhV0s-quU",
  //   name: "24/7 HIGH AT WORK lofi hip hop / phonk / vapor trap Radio",
  // },
  {
    id: "-5KAN9_CzSA",
    name: "coffee shop radio // 24/7 lofi hip-hop beats",
  },
  {
    id: "cqEydD0wAuI",
    name: "Japanese Lofi Playlist 2021 - No Copyright Lofi & Chillhop Beats",
  },
  {
    id: "b-EF93ebtyM",
    name: "ローファイ - japanese beats to work / study to",
  },
  // {
  //   id: "esX7SFtEjHg",
  //   name: "Coding in Chicago | LoFi Jazz Hip-Hop [Code - Relax - Study]",
  // },
  {
    id: "l7TxwBhtTUY",
    name: "lofi hip hop radio - sad & sleepy beats",
  },
  {
    id: "o33l32ZrIy8",
    name: "star wars ~ lofi beats to relax/study to",
  },
  // {
  //   id: "GukZ_0wgFhM",
  //   name: "🌙 24-7 lofi hip hop radio - late nite chat - every night 8pm-4am ♫",
  // },
  {
    id: "7NOSDKb0HlU",
    name: "lofi hip hop radio - beats to study/relax to 🐾",
  },
  {
    id: "56q3dOGNAJ8",
    name: "24/7 Bedroom Lo-Fi・Neo Chill Radio Ch1 - to sleep, relax, chillout",
  },
  {
    id: "L9Q1HUdUMp0",
    name: "r&b / hip-hop radio [ chill live stream － 24/7 rnb ]",
  },
  // {
  //   id: "U4pm3GHNkZU",
  //   name:
  //     "24/7 Dark Ambient Music Livestream for Studying Lovecraftian Tomes & Riding the Monorail",
  // },
  {
    id: "tNkZsRW7h2c",
    name:
      "Space Ambient Music LIVE 24/7: Space Traveling Background Music, Music for Stress Relief, Dreaming",
  },
  {
    id: "qt_urUz42vI",
    name: "Ambient Music For Reality Escape — Infinity Radio",
  },
  // {
  //   id: "QgV-b0AbOX4",
  //   name:
  //     "Super Intelligence - Binaural Beats Concentration Music for Work and Focus, Study Music",
  // },
  {
    id: "gfiPOgsNO2M",
    name:
      "Art of Techno 'Deep' Radio - Melodic - Progressive • House - Dj Mixes Live 24/7 by Trippy Code",
  },
  {
    id: "hQ3-RN3LKvA",
    name: "Dark Minimal Techno Trip Live Radio 24/7 Dark Monkey Music",
  },
];

export default stations;
