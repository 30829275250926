import React from "react";
import { useRecoilValue } from "recoil";
import { aboutShownState } from "../recoilState";

function About() {
  const aboutShown = useRecoilValue(aboutShownState);

  return (
    <div></div>
  );
}

const TeamMembers = () => {
  return (
    <div></div>
  );
};

const TeamMember = ({ name, username, image }) => {
  return (
    <div></div>
  );
};

const NewsletterForm = () => {
  return (
    <div></div>
  );
};

export default About;
